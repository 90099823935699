var breakpoint = require("../utils/breakpoint").default;
var { createGlobalStyle } = require("styled-components");
require("@fontsource/poppins/200.css");
require("@fontsource/poppins/300.css");
require("@fontsource/poppins/400.css");
require("@fontsource/poppins/500.css");
require("@fontsource/poppins/600.css");
require("@fontsource/poppins/700.css");

const globalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;
        scrollbar-width: thin;
    scrollbar-color:  ${(props) => props.theme.colors.scrollbarThumb} ${(
  props
) => props.theme.colors.scrollbarTrack};
    }

    ::-webkit-scrollbar{
    width: 16px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb{
    background-color: ${(props) => props.theme.colors.scrollbarThumb};

    &:hover{
      background-color: ${(props) => props.theme.colors.scrollbarThumbHover};
    }
  }

  ::-webkit-scrollbar-track{
    background-color: ${(props) => props.theme.colors.scrollbarTrack};
  }

    html {
        color: ${(props) => props.theme.colors.textColor};
        font-family: "Poppins", "sans-serif";
        font-size: 13px;
        font-weight: 300;
        background: ${(props) => props.theme.colors.background};
    }

    @media screen and (min-width: 320px) {
        html {
            font-size: calc(13px + 9 * ((100vw - 320px) / 3680));
        }
    }

    @media screen and (min-width: 4000px) {
        html {
            font-size: 22px;
        }
    }

    a, a:visited{
        text-decoration: none;
        color: inherit
    }

    .sidePadding{
        padding-left: ${(props) => props.theme.dimens.defaultSidePadding};
        padding-right: ${(props) => props.theme.dimens.defaultSidePadding};
        ${(props) =>
          breakpoint(
            props.theme,
            `padding-right: ${props.theme.dimens.tabletSidePadding};`,
            "above-tablet"
          )}
        ${(props) =>
          breakpoint(
            props.theme,
            `padding-left: ${props.theme.dimens.tabletSidePadding};`,
            "above-tablet"
          )}
        ${(props) =>
          breakpoint(
            props.theme,
            `padding-right: ${props.theme.dimens.laptopSidePadding};`,
            "above-laptop"
          )}
        ${(props) =>
          breakpoint(
            props.theme,
            `padding-left: ${props.theme.dimens.laptopSidePadding};`,
            "above-laptop"
          )}
        ${(props) =>
          breakpoint(
            props.theme,
            `padding-right: ${props.theme.dimens.desktopSidePadding};`,
            "above-desktop"
          )}
        ${(props) =>
          breakpoint(
            props.theme,
            `padding-left: ${props.theme.dimens.desktopSidePadding};`,
            "above-desktop"
          )}
    }

    main {
        overflow: hidden;
        max-width: ${(props) => props.theme.dimens.maxWidth};
        min-height: 100vh;
        min-height: -webkit-fill-available;
        margin: 0 auto;
    }

    ul{
      list-style: none;
    }
    
    ol, ul{
      list-style-position: inside;
    }

    li:nth-child(1n + 2){
      margin-top: 0.8rem;
    }

    table{
      text-align: left;
      padding: 0;
      border-spacing: 0;
    }

    th{
      border-bottom: 1px solid ${(props) => props.theme.colors.foreground};
    }
    
    td, tr, th{
      padding: 0.3rem;
    }

`;

exports.style = globalStyle;
