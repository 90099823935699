const orange = "#F26419";
const white = "#FFFFFF";
const lightGrey = "#C2C8D1";
const lightBlue = "#5586B4";
const darkBlue = "#283B58";
const darkBlueTint = "#344F71";
const extremeDarkBlue = "#010A0A";
const extremeDarkBlue22 = "#010A0A30";
const red = "#EB262F";

const theme = {
  colors: {
    accent: orange,
    error: red,
    secondary: lightBlue,
    background: darkBlue,
    backgroundTint: darkBlueTint,
    foreground: white,
    button: lightBlue,
    buttonShade: darkBlueTint,
    outlineButtonLight: white,
    outlineButtonDark: extremeDarkBlue,
    textOnButton: white,
    headerBackground: white,
    footerBackground: darkBlueTint,
    mobileMenuBackground: white,
    headerLinkColor: extremeDarkBlue,
    textColor: white,
    textColorOnForeground: extremeDarkBlue,
    cardGradientLight: darkBlueTint,
    cardGradientDark: darkBlue,
    shadowColor: extremeDarkBlue22,
    scrollbarThumb: lightBlue,
    scrollbarThumbHover: darkBlueTint,
    scrollbarTrack: lightGrey,
    popUpBackground: white,
    switchTrack: lightGrey,
    switchTrackActive: lightBlue,
    switchThumb: darkBlue,
    switchThumbHover: darkBlueTint,
  },
  dimens: {
    scrollMarginTop: "7rem",
    imageRadius: "4px",
    cardBorderRadius: "4px",
    maxWidth: "2400px",
    totalHeaderHeight: "4rem",
    contentHeaderHeight: "4rem",
    mobileMenuIconSize: "2.5rem",
    defaultSidePadding: "2rem",
    tabletSidePadding: "4rem",
    laptopSidePadding: "6rem",
    desktopSidePadding: "10rem",
  },
  break: {
    mobile: "320",
    tablet: "640",
    laptop: "1040",
    desktop: "1600",
    low: "500",
    mid: "700",
    high: "1200",
  },
  icons: {
    menu: "dark",
  },
};

exports.theme = theme;
