const React = require("react");
const { ThemeProvider } = require("styled-components");
const defaultTheme = require("./src/themes/default");
const GlobalStyle = require("./src/themes/globalStyle");

exports.wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={defaultTheme.theme}>
        <GlobalStyle.style/>
      {element}
    </ThemeProvider>
  );
};
