function breakpoint(
  theme,
  cssRule = "", //css rule to be applied at given breakpoint (e.g. "flex-direction: column")
  breakpoint = "above-mobile" //breakpoint at which the rule should be applied
) {
  const breakpoints = {
    "above-mobile": `(min-width: ${theme.break.mobile}px)`,
    "above-mobile-below-low": `(min-width: ${theme.break.mobile}px) and (max-height: ${theme.break.low}px)`,
    "above-mobile-above-low": `(min-width: ${theme.break.mobile}px) and (min-height: ${theme.break.low}px)`,
    "above-mobile-below-mid": `(min-width: ${theme.break.mobile}px) and (max-height: ${theme.break.mid}px`,
    "above-mobile-above-mid": `(min-width: ${theme.break.mobile}px) and (min-height: ${theme.break.mid}px)`,
    "above-mobile-below-high": `(min-width: ${theme.break.mobile}px) and (max-height: ${theme.break.high}px)`,
    "above-mobile-above-high": `(min-width: ${theme.break.mobile}px) and (min-height: ${theme.break.high}px)`,

    "below-mobile": `(max-width: ${theme.break.mobile}px)`,
    "below-mobile-below-low": `(max-width: ${theme.break.mobile}px) and (max-height: ${theme.break.low}px)`,
    "below-mobile-above-low": `(max-width: ${theme.break.mobile}px) and (min-height: ${theme.break.low}px)`,
    "below-mobile-below-mid": `(max-width: ${theme.break.mobile}px) and (max-height: ${theme.break.mid}px)`,
    "below-mobile-above-mid": `(max-width: ${theme.break.mobile}px) and (min-height: ${theme.break.mid}px)`,
    "below-mobile-below-high": `(max-width: ${theme.break.mobile}px) and (max-height: ${theme.break.high}px)`,
    "below-mobile-above-high": `(max-width: ${theme.break.mobile}px) and (min-height: ${theme.break.high}px)`,

    "above-tablet": `(min-width: ${theme.break.tablet}px)`,
    "above-tablet-below-low": `(min-width: ${theme.break.tablet}px) and (max-height: ${theme.break.low}px)`,
    "above-tablet-above-low": `(min-width: ${theme.break.tablet}px) and (min-height: ${theme.break.low}px)`,
    "above-tablet-below-mid": `(min-width: ${theme.break.tablet}px) and (max-height: ${theme.break.mid}px)`,
    "above-tablet-above-mid": `(min-width: ${theme.break.tablet}px) and (min-height: ${theme.break.mid}px)`,
    "above-tablet-below-high": `(min-width: ${theme.break.tablet}px) and (max-height: ${theme.break.high}px)`,
    "above-tablet-above-high": `(min-width: ${theme.break.tablet}px) and (min-height: ${theme.break.high}px)`,

    "below-tablet": `(max-width: ${theme.break.tablet}px)`,
    "below-tablet-below-low": `(max-width: ${theme.break.tablet}px) and (max-height: ${theme.break.low}px)`,
    "below-tablet-above-low": `(max-width: ${theme.break.tablet}px) and (min-height: ${theme.break.low}px)`,
    "below-tablet-below-mid": `(max-width: ${theme.break.tablet}px) and (max-height: ${theme.break.mid}px)`,
    "below-tablet-above-mid": `(max-width: ${theme.break.tablet}px) and (min-height: ${theme.break.mid}px)`,
    "below-tablet-below-high": `(max-width: ${theme.break.tablet}px) and (max-height: ${theme.break.high}px)`,
    "below-tablet-above-high": `(max-width: ${theme.break.tablet}px) and (min-height: ${theme.break.high}px)`,

    "above-laptop": `(min-width: ${theme.break.laptop}px)`,
    "above-laptop-below-low": `(min-width: ${theme.break.laptop}px) and (max-height: ${theme.break.low}px)`,
    "above-laptop-above-low": `(min-width: ${theme.break.laptop}px) and (min-height: ${theme.break.low}px)`,
    "above-laptop-below-mid": `(min-width: ${theme.break.laptop}px) and (max-height: ${theme.break.mid}px)`,
    "above-laptop-above-mid": `(min-width: ${theme.break.laptop}px) and (min-height: ${theme.break.mid}px)`,
    "above-laptop-below-high": `(min-width: ${theme.break.laptop}px) and (max-height: ${theme.break.high}px)`,
    "above-laptop-above-high": `(min-width: ${theme.break.laptop}px) and (min-height: ${theme.break.high}px)`,

    "below-laptop": `(max-width: ${theme.break.laptop}px)`,
    "below-laptop-below-low": `(max-width: ${theme.break.laptop}px) and (max-height: ${theme.break.low}px)`,
    "below-laptop-above-low": `(max-width: ${theme.break.laptop}px) and (min-height: ${theme.break.low}px)`,
    "below-laptop-below-mid": `(max-width: ${theme.break.laptop}px) and (max-height: ${theme.break.mid}px)`,
    "below-laptop-above-mid": `(max-width: ${theme.break.laptop}px) and (min-height: ${theme.break.mid}px)`,
    "below-laptop-below-high": `(max-width: ${theme.break.laptop}px) and (max-height: ${theme.break.high}px)`,
    "below-laptop-above-high": `(max-width: ${theme.break.laptop}px) and (min-height: ${theme.break.high}px)`,

    "above-desktop": `(min-width: ${theme.break.desktop}px)`,
    "above-desktop-below-low": `(min-width: ${theme.break.desktop}px) and (max-height: ${theme.break.low}px)`,
    "above-desktop-above-low": `(min-width: ${theme.break.desktop}px) and (min-height: ${theme.break.low}px)`,
    "above-desktop-below-mid": `(min-width: ${theme.break.desktop}px) and (max-height: ${theme.break.mid}px)`,
    "above-desktop-above-mid": `(min-width: ${theme.break.desktop}px) and (min-height: ${theme.break.mid}px)`,
    "above-desktop-below-high": `(min-width: ${theme.break.desktop}px) and (max-height: ${theme.break.high}px)`,
    "above-desktop-above-high": `(min-width: ${theme.break.desktop}px) and (min-height: ${theme.break.high}px)`,

    "below-desktop": `(max-width: ${theme.break.desktop}px)`,
    "below-desktop-below-low": `(max-width: ${theme.break.desktop}px) and (max-height: ${theme.break.low}px)`,
    "below-desktop-above-low": `(max-width: ${theme.break.desktop}px) and (min-height: ${theme.break.low}px)`,
    "below-desktop-below-mid": `(max-width: ${theme.break.desktop}px) and (max-height: ${theme.break.mid}px)`,
    "below-desktop-above-mid": `(max-width: ${theme.break.desktop}px) and (min-height: ${theme.break.mid}px)`,
    "below-desktop-below-high": `(max-width: ${theme.break.desktop}px) and (max-height: ${theme.break.high}px)`,
    "below-desktop-above-high": `(max-width: ${theme.break.desktop}px and (min-height: ${theme.break.high}px)`,
  };

  return `
    @media screen and ${breakpoints[breakpoint]} {
      ${cssRule}
    }
  `;
}

export default breakpoint;
